<template>
  <div class="line-bill-page pt-3">
    <div class="tw-container" style="max-width: 1200px; margin: auto">
      <p class="text-center tw-text-size18 fw-bolder mb-2">我的帳單</p>
      <hr />
      <NRBill></NRBill>
    </div>
  </div>
</template>

<script>
// components
import NRBill from '@/components/noRegistration/NR_Bill.vue'

export default {
  components: { NRBill }
}
</script>